import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const routes = [
    {
        path:"/",
        name:"index",
        component: () => import("./components/page/Index")
    },
    {
        path:"/login",
        name:"login",
        component: () => import("./components/login/Login"),
    },
    {
        path:"/inicio",
        name:"sgi",
        component: () => import("./components/fact_system/Principal"),
    },
    {
        path:"/inventario",
        name:"inventario",
        component: () => import("./components/fact_system/inventory/Inventory")
    },
    {
        path:"/facturacion",
        name:"facturacion",
        component: () => import("./components/fact_system/billing/Payroll")
    },
    {
        path:"/nomina",
        name:"nomina",
        component: () => import("./components/fact_system/payroll/RollEmp"),
    },
    {
        path:"/citas",
        name:"citas",
        component: () => import("./components/fact_system/booking/Booking"),
    },
    {
        path:"/contabilidad",
        name:"contabilidad",
        component: () => import("./components/fact_system/expenses/Expenses"),
    },
    {
        path:"/balance",
        name:"balance",
        component: () => import("./components/fact_system/balance/Balance"),
    },
]

const router = new VueRouter({
    mode:'history',
    routes
})

router.beforeEach((to, from, next)=>{
    document.title = 'Estación Pro';
    setTimeout(() => {}, 2000);
    const isLogged = localStorage.getItem("access") != null && 
        localStorage.getItem("access") != undefined 
        && localStorage.getItem("access") != "undefined" ? true : false
    if(to.name!='login' && to.name!='index' && !isLogged){
        next({name:'login'})
    } else if (to.name === 'login' && isLogged) {
        next({ name: 'sgi' })
    } else if (to.name === 'balance') {
        const groups = localStorage.getItem("groups")
        const administrator = groups.includes("view_adminenterprise") ? true : false
        if (administrator === false){
            next({name:'sgi'})
        } else {
            next()
        }
    } else {
        next()
    }
})
export default router 