import {ApiAuth} from "../login/ApiAuth"
const apiAuth = new ApiAuth()
export default class Api{
    constructor(){
        this.SERVER_URL = process.env.VUE_APP_SERVER_URL;
        this.TOKEN_URL = this.SERVER_URL + 'accounts/token/';
        this.USUARIO = process.env.USER;
        this.PASSWORD = process.env.PASSWORD;
        this.credenciales = {username:this.USUARIO, password:this.PASSWORD}
    }

    async getToken(){
        const token = apiAuth.getToken()
        return token;
    }
    async get(nombre, id=-1){
        const token = await this.getToken();

        let url = this.SERVER_URL + nombre + "/";
        if (id !== -1) {
            url += id + "/"
        }

        const res = await fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token.access,
            }
        });
        const items = await res.json();

        if (items.results === undefined){
            return items
        }
        return items.results
    }

    async getParams(nombre, id=-1, params){
        const token = await this.getToken();

        let url = this.SERVER_URL + nombre + "/";
        if (id === -1) {
            url += `?${params}`
        } else{
            url += id + `?${params}`
        }
        
        const res = await fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token.access,
            }
        });
        const items = await res.json();

        if (items.results === undefined){
            return items
        }
        return items.results
    }

    async getParamsPaginator(nombre, id=-1, params){
        const token = await this.getToken();

        let url = this.SERVER_URL + nombre + "/";
        if (id === -1) {
            url += `?${params}`
        } else{
            url += id + `?${params}`
        }
        
        const res = await fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token.access,
            }
        });
        const items = await res.json();

        // if (items.results === undefined){
        //     return items
        // }
        return items
    }
    
    async getFile(nombre, params){
        const token = await this.getToken();
        let url = this.SERVER_URL + nombre + "/";
        url += `?${params}`
        let res = await fetch(url, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + token.access,
            }
        })
        
        res.blob().then(blob => {
            let filename = res.headers.get("filename")
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
        });
    }

    async save(nombre, obj){
        const token = await this.getToken();

        let url = this.SERVER_URL + nombre + "/";
        let method = "POST"

        if(obj.id !== -1){
            method = "PUT";
            url += obj.id + "/";
        }

        // console.log(obj, "Esto se pasa en save()")
        let r = await fetch(url, {
            method: method,
            body: JSON.stringify(obj),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token.access,
            }
        })
        const data = await r.json();
        
        if (!r.ok){
            return data;
        }

        return r;
    }

    async delete(nombre, id){
        const token = await this.getToken();

        let url = this.SERVER_URL + nombre + "/" + id + "/"
        // console.log(url, "url de borrar")

        let r = fetch(url, {
            method: 'DELETE',
            headers:{
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token.access,
            }
        })
        .catch(error => console.error('Error:', error))
        return r
    }
}