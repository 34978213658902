<template>
  <v-app>
    <v-card>
      <v-navigation-drawer
        v-show="showNav"
        app
        expand-on-hover
        :mini-variant="miniVariant"
        permanent
      >
        <v-list>
          <v-list-item class="px-2">
            <v-list-item-avatar>
              <v-icon>perm_identity</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="title">
                {{usuario}}
              </v-list-item-title>
              <v-list-item-subtitle @click="logout">
                <!-- <v-icon>logout</v-icon> -->
                <v-btn 
                  x-small
                  rounded
                  color="error"
                  depressed
                  >
                  Salir
                </v-btn>                
              </v-list-item-subtitle>

            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list nav dense>
          <div v-for="m in menu" :key="m.nombre">
            <router-link :to="m.ruta">
              <v-list-item link>
                <v-list-item-icon><v-icon color="teal darken-2">{{m.icono}}</v-icon></v-list-item-icon>
                <v-list-item-title>{{m.nombre}}</v-list-item-title>
              </v-list-item>
            </router-link>
          </div>
        </v-list>
        <template v-slot:append>
          <v-menu
            top
            offset-y
            rounded:lg            
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list 
                nav 
                dense
              >
                <v-list-item
                  v-bind="attrs"
                  v-on="on"
                  @click="queryEnt"
                >
                  <v-list-item-icon>
                    <v-icon 
                      color="teal darken-2"
                    >
                      change_circle
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{enterprise.name}}</v-list-item-title>
                </v-list-item>
              </v-list>
            </template> 
            <v-list dense nav>
              <div v-for="item in enterprises" :key="item.id">
                <v-list-item  @click="selectEntReload(item)">
                <v-list-item-icon><v-icon color="teal darken-2">logout</v-icon></v-list-item-icon>
                <v-list-item-title>{{item.name}}</v-list-item-title>
              </v-list-item>
              </div>
            </v-list>         
          </v-menu>
        </template>
      </v-navigation-drawer>
      <router-view></router-view>
    </v-card>
  </v-app>
</template>

<script>
// import Principal from './components/Principal';
import {ApiRoll} from './components/fact_system/payroll/ApiRoll'
import messagesMixin from "./mixins/messagesMixin"

export default {
  name: 'App',
  mixins:[messagesMixin],
  // components: {
  //   Principal,
  // },
  data() {
    return {
      drawer: true,
      menu:[
        {ruta:"/inicio", nombre:"Inicio", icono:"home"},
        {ruta:"/facturacion", nombre:"Facturación", icono:"paid"},
        {ruta:"/inventario", nombre:"Inventario", icono:"store"},
        {ruta:"/nomina", nombre:"Nomina", icono:"account_balance_wallet"},
        {ruta:"/citas", nombre:"Citas", icono:"event_available"},
        {ruta:"/contabilidad", nombre:"Contabilidad", icono:"account_balance"},
        {ruta:"/balance", nombre:"Balance", icono:"leaderboard"},
      ],
      mini: true,
      miniVariant:true,
      usuario:"",    
      token:"",
      api: new ApiRoll(),
      enterprises: [],
      enterprise:{}
    }
  },
  methods:{
    init(){
      this.usuario = localStorage.getItem("usuario");
      this.token = localStorage.getItem("access") 
      this.enterprise["name"] = localStorage.getItem("Sucursal")
      const groups = localStorage.getItem("groups")
      const administrator = groups.includes("view_adminenterprise") ? true : false
      if (administrator === false){
        this.menu.pop()
      }
    },
    logout(){
      localStorage.removeItem("usuario")
      localStorage.removeItem("access")
      localStorage.removeItem("refresh")
      localStorage.removeItem("Sucursal")
      localStorage.removeItem("groups")
      this.usuario = ""
      this.$router.push("/login")
    },
    login(user){
      this.usuario = user
      this.token = localStorage.getItem("access")       
      localStorage.setItem("usuario", this.usuario)
      this.loginEnt()
    },
    async loginEnt(){
      await this.queryEnt()
      // await this.userDetail()
      this.selectEnt(this.enterprises[0])
    },
    // async userDetail(){
    // let isStaff = await this.api.getUserDetail(this.usuario)
    //   localStorage.setItem("isStaff", isStaff)
    // },
    async queryEnt(){
      this.enterprises = await this.api.getSucursales()
    },
    selectEnt(item){
      this.enterprise = item
      localStorage.setItem("Sucursal", this.enterprise.name)
      localStorage.setItem("SucursalId", this.enterprise.id)
    },
    selectEntReload(item){
      this.enterprise = item
      localStorage.setItem("Sucursal", this.enterprise.name)
      localStorage.setItem("SucursalId", this.enterprise.id)
      this.$router.go()
    },
  },
  computed: {
    showNav() {
      return this.usuario && this.token;
    },
  },
  created(){
    this.init()
    this.$root.$on('login', (user)=>this.login(user))
  }
}
</script>

<style>
a:link {
  text-decoration: none;
}
</style>