import Api from "../Api"

export class ApiRoll extends Api{
    constructor(){
        super()
    }

    async getEmpleados(id=-1){
        return await super.get("nomina/empleados", id)
    }

    async saveEmpleados(obj){
        return await super.save("nomina/empleados", obj)
    }

    async delEmpleados(id){
        return await super.delete("nomina/empleados", id)
    }

    async getSucursales(id=-1){
        return await super.get("accounts/sucursales", id)
    }

    async getItemsS(id=-1, params){
        return await super.getParams("nomina/servicio-empleado", id, params)
    }

    async saveLiquidacion(obj){
        return await super.save("nomina/liquidaciones", obj)
    }

    async getLiquidaciones(id=-1){
        return await super.get("nomina/liquidaciones", id)
    }

    async getLiquidacionesP(params, id=-1){
        return await super.getParams("nomina/liquidaciones", id, params)
    }

    async getLiquidacionesPaginator(params, id=-1){
        return await super.getParamsPaginator("nomina/liquidaciones", id, params)
    }
}   
