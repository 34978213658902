import Vue from 'vue';
import "bootstrap/scss/bootstrap.scss";
import Vuetify from 'vuetify/lib/framework';
import 'vuetify/dist/vuetify.min.css'
import '../assets/css/main.scss'

Vue.use(Vuetify);

export default new Vuetify({
});
