import Vue from 'vue'
import 'bootstrap/dist/js/bootstrap.bundle.js';
import App from './App.vue'

import VueSweetalert2 from 'vue-sweetalert2';

import vuetify from './plugins/vuetify'
import Vuetify from 'vuetify'

import router from "./router"

Vue.config.productionTip = false

Vue.use(Vuetify);
Vue.use(VueSweetalert2);

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
